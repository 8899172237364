@import 'variables';

* {
    box-sizing: border-box;
}

html,
body,
#root {
    height: 100%;
    margin: 0;
    padding: 0;
    position: relative;
    overflow: hidden;
}
body {
    background: #171717;
    //background: #024b04 !important;
    background: linear-gradient(180deg, #171717 0%, #171717 100%);
    color: #fff;
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
        'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 16px;
    line-height: 1.65em;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

.App {
    display: flex;
    min-height: 100%;
    height: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #2c3e50;
    overflow: hidden;
    background: #171717;
    background: linear-gradient(180deg, #171717 0%, #171717 100%);

    & > div {
        overflow: hidden;
        height: 100%;
    }

    flex-direction: column;

    @include lg() {
        flex-direction: row;
    }
}

nav {
    display: flex;
    align-items: center;
    justify-content: space-around;
    background-color: #f2f2f2;

    @include lg() {
        flex-basis: 400px;
    }

    a {
        color: DodgerBlue;
        text-decoration: none;
        text-transform: uppercase;
        font-weight: bold;
        padding: 1em 0;
        margin: 0 1em;
        border-bottom: 2px solid transparent;
    }
}

a {
    color: DodgerBlue;
    text-decoration: none;
    text-transform: uppercase;
    font-weight: bold;
}

/* a.router-link-exact-active {
  border-color: inherit;
}
*/

main {
    flex-grow: 1;

    display: grid;
    grid-template: 'main';
    flex: 1;
    position: relative;
    overflow: hidden;
    background: #171717;
    /*background: linear-gradient(180deg, #171717 0%, #171717 100%);*/

    @include lg() {
        min-height: 100%;
        height: 100%;
    }
}

main > * {
    grid-area: main; /* Transition: make sections overlap on same cell */
    flex: 1 1 auto;
    /*background-color: white;*/
    position: relative;
    height: 100vh; /* To be fixed */
}

.page {
    top: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    color: #fff;
}

main > :first-child {
    z-index: 1; /* Prevent flickering on first frame when transition classes not added yet */
}

/* Transitions */
.next-exit-active {
    animation: leaveToLeft 500ms both cubic-bezier(0.165, 0.84, 0.44, 1);
    z-index: 0;
}

.next-enter-active {
    animation: enterFromRight 500ms both cubic-bezier(0.165, 0.84, 0.44, 1);
    z-index: 1;
}

.prev-exit-active {
    animation: leaveToRight 500ms both cubic-bezier(0.165, 0.84, 0.44, 1);
    z-index: 1;
}

.prev-enter-active {
    animation: enterFromLeft 3000ms both cubic-bezier(0.165, 0.84, 0.44, 1);
    z-index: 0;
}

@keyframes leaveToLeft {
    from {
        transform: translateX(0);
    }
    to {
        transform: translateX(-25%);
        filter: brightness(0.5);
    }
}

@keyframes enterFromLeft {
    from {
        transform: translateX(-25%);
        filter: brightness(0.5);
    }
    to {
        transform: translateX(0);
    }
}

@keyframes leaveToRight {
    from {
        transform: translateX(0);
    }
    to {
        transform: translateX(100%);
    }
}

@keyframes enterFromRight {
    from {
        transform: translateX(100%);
    }
    to {
        transform: translateX(0);
    }
}
